import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import Image from "gatsby-image"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import Bio from "../components/Bio"
import PageHeading from '../components/PageHeading'
import SubHeading from '../components/SubHeading'
import TertiaryHeading from '../components/TertiaryHeading'
import LinkContainer from '../components/LinkContainer'

const WorkPage = () => (
  <StaticQuery
    query={graphql`
      query {
        ogImg: file(relativePath: { eq: "images/handy_pro-og.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        jhgImg: file(relativePath: {eq: "images/shofar-og.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 960) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        pybImg: file(relativePath: {eq: "images/protect-your-brain-og.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 960) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        formImg: file(relativePath: {eq: "images/react-form.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 960) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        portalImg: file(relativePath: {eq: "images/react-portal.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 960) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        svImg: file(relativePath: {eq: "images/stock-viewz.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 960) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        wuaImg: file(relativePath: {eq: "images/where-u-at.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 960) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        uvImg: file(relativePath: {eq: "images/u-vote.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 960) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        tolifeImg: file(relativePath: {eq: "images/to-life-the-movie.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 960) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        cloverImg: file(relativePath: {eq: "images/clovers-compression.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 960) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => {
      return (
        <Layout>
          <SEO
            title="My Work"
            keywords={[
              "wordpress plugin",
              "react form",
              "object oriented programming",
              "open source contributor",
              "landing pages",
              "online petitions",
              "command line interfaces",
              "git",
              "twilio blog",
              "scotch.io",
              "javascript tutor",
            ]}
            image={{
              src: data.ogImg.childImageSharp.fluid.src,
              height: "1200",
              width: "630",
              alt: "Wesley L. Handy, Full Stack Web Developer, at your service"
            }}
            description="Wesley develops solutions for small businesses and enterprise clients to connect your organization to new and existing customers. See a brief portfolio of his work in crafting webforms, webapplication, websites, and more."
          />
          <PageHeading style={{marginBottom: "0"}}>Wesley L. Handy</PageHeading>
          <SubHeading style={{margin: "5px 0 30px 0"}}>Full Stack Web Developer</SubHeading>
          <TertiaryHeading>Select Projects</TertiaryHeading>
          <dl>
            <dt>React</dt>
            <dd>
              <p>I am most proud of the web application I have built to provide a fully customizable web-form to handle CBN donations on multiple platforms. The first public iteration of the project includes a Wordpress plugin. The plugin serves my form to site visitors, and serves a complete admin portal to administrators. Both the form and portal are built with modern React (v. 16.8.2).</p>
              <dl style={{paddingLeft: "20px"}}>
                <dt>Form</dt>
                <dd><Image fluid={data.formImg.childImageSharp.fluid} alt="Oprhan's Promise Donation Page"/></dd>
                <dt>Portal&mdash;includes Color Picker, Copy To Clipboard, and Markdown Previewer</dt>
                <dd><Image fluid={data.portalImg.childImageSharp.fluid} alt="Oprhan's Promise Administration Portal"/></dd>
              </dl>
              <p>I have also built over a dozen pages that incorporate <em>React-without-JSX</em> forms, similar to and including these, among others:</p>
              <dl style={{paddingLeft: "20px"}}>
                <dt><a href="https://www.cbn.com/israel/jewish-holidays-guide/">Jewish Holidays Guide</a></dt>
                <dd><a href="https://www.cbn.com/israel/jewish-holidays-guide/"><Image fluid={data.jhgImg.childImageSharp.fluid} alt="CBN Israel Jewish Holidays Guide"/></a></dd>
                <dt><a href="http://www.cbn.com/special/protectyourbrain/">Protect Your Brain</a></dt>
                <dd><a href="http://www.cbn.com/special/protectyourbrain/"><Image fluid={data.pybImg.childImageSharp.fluid} alt="CBN Health Series Protect Your Brain"/></a></dd>
              </dl>
            </dd>
            <dt>JavaScript/jQuery</dt>
            <dd>
              <p>With modern browsers, there is less need for jQuery in general, though I still use it with many of my work projects because our team still uses it. I haven&rsquo;t found a good use case for jQuery over vanilla JavaScript, however, other than the simplicity of jQuery functions, the piece of mind they will work cross-broswer, and the ability to consistenly use chaining. since jQuery functions always return jQuery.</p>
              <p>Here are many of the features I have built day-in, day-out (code snippets on request):</p>
              <ul>
                <li>Script Loader&mdash;async or not</li>
                <li>Lazy-Image Loader&mdash;using data attributes and element.onload events</li>
                <li>postMessage between iframe and parent window, to handle resizing, to pass data to prefill forms, to set up tracking activities</li>
                <li>Fetch API, Promise API, RequestAnimationFrame API & Custom Scrolling</li>
                <li>DOM Manipulation & Event Handling</li>
                <li>Handle HTML5 Video Players & Video Events</li>
              </ul>
            </dd>
            <dt>Node.js/MongoDB</dt>
            <dd>
              <p>I use Node daily for package management, bundling, copying files and manipulating their inner contents programattically, task-running, creating development servers and test APIs. I have also created several Full-Stack applications built upon and served by Node/Express APIs.</p>
              <p>All of my live Node/Express applications use MongoDB to store and retrieve user and application data as well as to store session data. Here are a few:</p>
              <dl style={{paddingLeft: "20px"}}>
                <dt><a href="https://github.com/wesleylhandy/bos-stalker" aria-label="Link to Github Repository">Bos Stalker</a></dt>
                <dd>This application has no front-end but is a web-worker designed to listen to a particular twitter stream for whenever it mentioned a specific term and then send me a text-message so I could go online and buy something before it sold out.</dd>
                <dt><a href="https://github.com/wesleylhandy/twitter-bot" aria-label="Link to Github Repository">Personal Twitter Assistant</a></dt>
                <dd>When activated, this &ldquo;bot&rdquo; listens to my personal twitter stream and responds to every new follow with a random message, it likes and retweets any tweet where I am mentioned, and favorites all tweet replies to me, and follows anyone who interacts with me directly. When activated, the amount of interaction with my personal twitter account is significantly larger than without, and due to the interaction, my number of followers increases by about ten per week.</dd>
                <dt><a href="https://github.com/wesleylhandy/stock-viewer" aria-label="Link to Github Repository">Stock Viewz</a></dt>
                <dd>
                  <p>An app for tracking stocks over time. Uses websockets to update in real time across instances.</p> 
                  <a href="https://github.com/wesleylhandy/stock-viewer" aria-label="Link to Github Repository"><Image fluid={data.svImg.childImageSharp.fluid} alt="Stock Viewz"/></a>
                </dd>
                <dt><a href="https://github.com/wesleylhandy/where-u-at" aria-label="Link to Github Repository">Where U @?</a></dt>
                <dd>
                 <p>An app connected to Yelp and Twitter via json-web-tokens, to communicate with others places you intend to visit tonight for food, coffee, or drink</p>
                  <a href="https://github.com/wesleylhandy/where-u-at" aria-label="Link to Github Repository"><Image fluid={data.wuaImg.childImageSharp.fluid} alt="Where You At?"/></a>
                </dd>
                <dt><a href="https://github.com/wesleylhandy/uvote" aria-label="Link to Github Repository">U Vote</a></dt>
                <dd>
                  <p>An app for creating and sharing polls with friends. Utilizes Server-Side Rendering to enable sharing of specific polls.</p>
                  <a href="https://github.com/wesleylhandy/uvote" aria-label="Link to Github Repository"><Image fluid={data.uvImg.childImageSharp.fluid} alt="You Vote"/></a>
                </dd>
              </dl>
            </dd>
            <dt>C#/.Net</dt>
            <dd>
              <p>Most of the pages I build daily at work are .Net pages. I do mostly styling of the front end, but often incorporate inline C# scripts to do a variety of helpful tasks pre-render. I have created MultiViews and Custom Controls to updating the view based on query parameters.</p>
              <p>Compare:</p>
              <ul style={{paddingLeft: "20px"}}>
                <li><a href="https://www.cbn.com/giving/general/operationblessingsurgeries.aspx?so=naomi">https://www.cbn.com/giving/general/operationblessingsurgeries.aspx?so=naomi</a></li>
                <li><a href="https://www.cbn.com/giving/general/operationblessingsurgeries.aspx?so=rathma">https://www.cbn.com/giving/general/operationblessingsurgeries.aspx?so=rathma</a></li>
              </ul>
            </dd>
            <dt>PHP/MySql/Wordpress</dt>
            <dd>
              <p>I have already mentioned my plugin under <strong>React</strong> above. However, for this plugin, I create custom <strong>MySQL</strong> tables on the Wordpress Database. I utize each CRUD operation to insert new records, update existing record, get single or lists of records, and of course delete as needed. Moreover the entire plugin is built utilizing Object Oriented PHP (PHP v7+) and the functions provided by Wordpress</p>
              <p>Besides this plugin, I have also built and maintained minisites in PHP, like the following:</p>
              <dl style={{paddingLeft: "20px"}}>
                <dt><a href="http://tolifethemovie.com/">To Life The Movie</a></dt>
                <dd>
                  <p>Mini-site for the Daytime Emmy Award nominated documentary&mdash;<em>To Life</em>.</p>
                  <a href="http://tolifethemovie.com/" aria-label="Link to To Life Movie Site"><Image fluid={data.tolifeImg.childImageSharp.fluid} alt="To Life The Movie"/></a>
                </dd>
              </dl>
              <p>I also am building an e-commerce site for a local business on wordpress using woocommerce:</p>
              <dl style={{paddingLeft: "20px"}}>
                <dt><a href="https://cloverscompression.com">Clovers Compression</a></dt>
                <dd><a href="https://cloverscompression.com" aria-label="Link to Clovers Compression Website"><Image fluid={data.cloverImg.childImageSharp.fluid} alt="Clovers Compression"/></a></dd>
              </dl>
            </dd>
          </dl>
          <TertiaryHeading>Writings</TertiaryHeading>
          <ul>
            <li><p>This Site&rsquo;s <Link to="/blog/" aria-label="Link to Blog Page">Blog</Link></p></li>
            <li><p><em>Writing Your Own JavaScript Promises</em><br/><a href="https://scotch.io/@wesleylhandy/writing-your-own-javascript-promises">https://scotch.io/@wesleylhandy/writing-your-own-javascript-promises</a></p></li>
            <li><p><em>Beat the Rush on Exclusive Twitter Deals with Node.js and Twilio SMS</em><br/><a href="https://www.twilio.com/blog/2017/12/exclusive-twitter-deals-nodejs-twilio.html">https://www.twilio.com/blog/2017/12/exclusive-twitter-deals-nodejs-twilio.html</a></p></li>
          </ul>
          <TertiaryHeading>Open Source & Community Work</TertiaryHeading>
          <ul>
            <li>
              <p>Three Pull Requests on <strong><a href="https://github.com/gatsbyjs/gatsby">GatsbyJS</a></strong>, 2 Merged, 1 Pending Merge</p>
              <p>Member of Gatsby Maintainers Group on <strong>Github</strong></p>
              <ol>
                <li>Merged: Updating Documentation - Updated references to import of <strong>@emotion/core</strong> from previous and broken usage of <strong>react-emotion</strong> npm package.</li>
                <li>Merged: Moving <strong>gatsby-source-mongodb</strong> plugin to version 3+ of Node MongoDB Driver, added capabaility to pass a full connection string to the plugin</li>
                <li>Pending: Fix Bug where relational references to <strong>MongoDB</strong> ObjectIDs are dropped with a recursive funciton to preserve them in all documents imported into <strong>graphql</strong></li>
              </ol>
            </li>
            <li>
              <p>Participant in local bridage of <strong><a href="http://code4hr.org/">Code For America</a></strong></p>
            </li>
          </ul>
          <Bio/>
          <LinkContainer>
            <Link to="/">Go back to the homepage</Link>
          </LinkContainer>
        </Layout>
      )
    }}
  />
)

export default WorkPage
